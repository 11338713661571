import React from "react";
import styled, {css} from "styled-components";
import {MdDone, MdDelete} from 'react-icons/md';
import axios from "axios";
import { useTodoDispatch } from "../TodoContext";

const Remove = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #dee2e6;
    font-size: 24px;
    cursor: pointer;
    &:hover {
        color: #ff6b6b;
    }
    display: none;
`;

const TodoItemBlock = styled.div`
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    &:hover {
        ${Remove} {
            display: initial;
        }
    }
`;

const CheckCircle = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 16px;
    border: 1px solid #ced4da;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    cursor: pointer;
    ${props =>
        props.done &&
        css`
            border: 1px solid #38d9a9;
            color: #38d9a9;
        `
    }
`;

const Text = styled.div`
    flex: 1;
    font-size: 21px;
    color: #495057;
    ${props =>
        props.done &&
        css`
            color: #ced4da;
        `
    }
`;



function TodoItem({id, done, text}) {

    const dispatch = useTodoDispatch();

    const onToggle = async () => {
        
        try {

            await axios.put(`/api/put/${id}`, {done: !done});

            dispatch({ type: 'TOGGLE', id });

        } catch(error) {
            console.log("todo를 수정하는데 실패", error);
        }
    };
    const onRemove = async () => {
        
        try {

            await axios.delete(`/api/delete/${id}`);

            dispatch({ type: 'REMOVE', id })

        } catch (error) {
            console.log("todo를 삭제하는데 실패", error);
        }
    };

    return (
        <TodoItemBlock>
            <CheckCircle done={done} onClick={onToggle}>
                {done && <MdDone />}
            </CheckCircle>
            <Text done={done}>{text}</Text>
            <Remove onClick={onRemove}>
                <MdDelete />
            </Remove>
        </TodoItemBlock>
    );
}

export default React.memo(TodoItem);